import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, Container } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import axios from 'axios';
import AllowedField from '../components/AllowedField';
import CommomSelect from '../../components/CommonSelect';
import { legacyMaskCPF, legacyMaskCartaoSus, legacyMaskDate, legacyMaskPhone, legacyMaskZipCode, legacyMaskEmail, legacyMaskRegex } from '../components/TextMaskCustom';

const WhiteCheckbox = withStyles({
  root: {
    color: 'white',
    '&$checked': {
      color: 'white',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const theme = createMuiTheme({
  overrides: {
    MuiSelect: {
      selectMenu: {
        textAlign: 'left',
        textTransform: 'initial'
      }
    },
    MuiFormControl: {
      root: {
        width: '100%'
      }
    },
    MuiSvgIcon: {
      root: {
        fontSize: '1em'
      }
    },
    MuiTypography: { 
      body1: { 
        fontSize: '13px',
        fontWeight: 500
      }
    },
    MuiInputLabel: {
      root: {
        color: 'white',
        fontSize: '13px',
        fontWeight: 500
      },
    },
    MuiFormLabel: {
      root: {
        '&.Mui-focused': {
          color: 'white',
          fontWeight: 500,
          fontSize: '13px',
        },
        '&.Mui-error': {
          color: 'unset'
        }
      },
      asterisk: {
        '&.Mui-error': {
          color: 'unset'
        }
      }
    },
    MuiFormHelperText :{
      root: {
        color: 'white',
        fontSize: '13px'
      }
    },
    MuiInput: {
      root: {
        fontSize: '0.85rem',
        color: 'white',
        fontFamily: "Titillium Web, sans-serif"
      },
      input: {
        textTransform: 'uppercase',
        color: 'white'
      },
      underline: {
        '&:hover:not(.Mui-disabled):before': {
          borderBottomColor: 'white',
        },
        "&::before": {
          borderBottom: '2px solid white'
        },
        "&::after": {
          borderBottom: '2px solid white'
        },
        '&.Mui-error::after': {
          borderBottomColor: 'white'
        },
        '&.Mui-error': {
          // borderBottomColor: '#f44336',
          borderBottomColor: 'white',
        }
      }
    }
  }
});

const useStyles = makeStyles((theme) => ({
  root: {},
  containerForm: {
    padding: 0,
    marginTop: '17px'
  },
  gridForm: {
    textAlign: 'left',
    padding: '5px 15px 10px 0px'
  },
  gridDependent: {
    padding: '5px 15px 10px 15px',
  },
  customBtn: {
    fontFamily: "Titillium Web, sans-serif",
    borderRadius: '0.35em',
    transitionTimingFunction: 'cubic-bezier(0.400,0.000,0.200,1.000)',
    transitionDuration: '0.3s',
    boxShadow: 'none',
    border: '0px',
    fontSize: '1.11em',
    fontStyle: 'normal',
    fontWeight: '600 !important',
    lineHeight: '1.3',
    width: '334px',
    height: '51px',
    marginBottom: 5,
    '@media(max-width: 425px)': {
      width: 115,
    },
  },
  whiteBg: {
    backgroundColor: 'white',
    color: props => props.primary_color,
    marginTop: 35,
    '&:hover': {
      backgroundColor: '#292929',
      color: 'white !important',
    }
  },
  fieldText: {
  },
  formControl: {
    '@media(max-width: 425px)': {
      marginTop: '20px'
    }
  },
  bigInputLabel: {
    fontSize: '0.75rem',
    '&.Mui-focused': {
      fontSize: '0.75rem',
    },
    '@media(max-width: 520px)': {
      top: -10
    },
    '@media(max-width: 386px)': {
      top: -20
    },
  }
}));

export default function RegistrationForm({ globalSchemaSpa, saving, healthInstitutionField, subGroupOptions, crmCoremField, accordingLawField, workSectionField, workSectionFieldOptions, user, setUser, userErrors}) {
  const classes = useStyles(globalSchemaSpa);
  const nameRef = useRef('');
  const socialNameRef = useRef('');
  const emailRef = useRef('');
  const motherNameRef = useRef('');
  const responsibleEmailRef = useRef('');

  const cpfRef = useRef('');
  const cpfDivRef = useRef('');
  const passportCodeRef = useRef('');
  const passportCodeDivRef = useRef('');

  const cartaoSusRef = useRef('');
  const birthdateRef = useRef('');
  const phoneRef = useRef('');
  const cepRef = useRef('');
  const streetRef = useRef('');
  const streetNumberRef = useRef('');
  const neighborhoodRef = useRef('');
  const cityRef = useRef('');
  const stateRef = useRef('');

  const medicalConditionOthersRef = useRef('');

  const [medicalConditionList, setMedicalConditionList] = useState([]);
  const [showMedicalConditionOther, setShowMedicalConditionOther] = useState(false);

  function buscaCep(e) {
    const cep = e.target.value;
    if (!cep) return null;
    if (cep.length != 9) return null;

    axios.get(`https://viacep.com.br/ws/${cep}/json/`).then(res => {
      const {
        logradouro: street,
        localidade: city,
        bairro: neighborhood,
        uf
      } = res.data;
      
      updateAddressFields({ street, city, neighborhood, uf });
      
      if(street) streetRef.current.setAttribute('readonly', 'true');
      if(city) cityRef.current.setAttribute('readonly', 'true');
      if(neighborhood) neighborhoodRef.current.setAttribute('readonly', 'true');
      if(uf) stateRef.current.setAttribute('readonly', 'true');
      
      streetNumberRef.current.focus();
    }).catch(function(error){
      // It's ok to ignore this error, as it's not a essencial feature
    });
  }

  function updateAddressFields({ street, city, neighborhood, uf }) {
    if (street) streetRef.current.value = street;
    if (city) cityRef.current.value = city;
    if (neighborhood) neighborhoodRef.current.value = neighborhood;
    if (uf) stateRef.current.value = uf;
  }

  function handleCheckbox({ target }){
    const { checked, name } = target;
    setUser({ ...user, [name]: checked });
  }


  function handleEmail(e){
    const checked = e.target.checked;
    let query;
    if (checked){
      query = { email: '' };
    } else {
      query = { responsible_email: '' };
    }
    setUser({ ...user, ...query, dependent: checked });
  }

  function handleMedicalConditions({ target }) {
    const { checked, name, value } = target;

    const currentMedicalConditionList = [...medicalConditionList];

    if (checked) {
      currentMedicalConditionList.push(value);
    } else {
      const itemIndex = currentMedicalConditionList.findIndex(item => item === value);
      if (itemIndex !== -1) {
        currentMedicalConditionList.splice(itemIndex, 1);
      }
    }

    setMedicalConditionList(currentMedicalConditionList);
  }

  useEffect(() => {
    const othersItemFounded = medicalConditionList.findIndex(i => i === 'outros') !== -1;
    setShowMedicalConditionOther(othersItemFounded);
  }, [medicalConditionList]);

  useEffect(() => {
    if(user && user.dependent) legacyMaskEmail(responsibleEmailRef);
  }, [user.dependent]);

  useEffect(() => {
    if (!cpfRef.current && !passportCodeRef.current) return null;

    if (user.foreigner){
      cpfDivRef.current.style.display = 'block';
      passportCodeDivRef.current.style.display = 'none';
      passportCodeRef.current.value = '';
    } else {
      cpfDivRef.current.style.display = 'none';
      passportCodeDivRef.current.style.display = 'block';
      cpfRef.current.value = '';
    }
  }, [user.foreigner]);

  useEffect(() => {
    legacyMaskCPF(cpfRef);
    legacyMaskRegex(passportCodeRef, /^\w+$/);

    legacyMaskCartaoSus(cartaoSusRef);
    legacyMaskDate(birthdateRef);
    legacyMaskPhone(phoneRef);
    legacyMaskZipCode(cepRef);
    legacyMaskRegex(nameRef, /^[^0-9!@#$%ˆ&*()_+<>,\-=.;\/"{}\[\]~`\\]+$/);
    legacyMaskRegex(socialNameRef, /^[^0-9!@#$%ˆ&*()_+<>,\-=.;\/"{}\[\]~`\\]+$/);
    legacyMaskRegex(motherNameRef, /^[^0-9!@#$%ˆ&*()_+<>,\-=.;\/"{}\[\]~`\\]+$/);
    legacyMaskRegex(cityRef, /^[^!@#$%ˆ&*()_+<>,\-=.;\/"{}\[\]~`\\]+$/);
    legacyMaskRegex(neighborhoodRef, /^[^!@#$%ˆ&*()_+<>,\-=.;\/"{}\[\]~`\\]+$/);
    legacyMaskEmail(emailRef);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container className={classes.containerForm}>
        <Grid container>
          <AllowedField allowed={subGroupOptions.length > 0}>
            <Grid item xs={12} md={12} className={classes.gridForm}>
              <FormControl className={classes.formControl}>
                <InputLabel
                  className={classes.inputLabel}
                  error={!!userErrors.health_worker_sub_group}
                  id="demo-simple-select-label"
                >
                  SUB-GRUPO
                </InputLabel>
                <CommomSelect
                  name="health_worker_sub_group_id"
                  items={subGroupOptions.map((group) => ({
                    value: group.id,
                    label: group.text,
                  }))}
                />
              </FormControl>
              <p style={{ color: "#f44336", margin: 0, textAlign: "left" }}>
                {userErrors.health_worker_sub_group}
              </p>
            </Grid>
          </AllowedField>
          <AllowedField allowed={crmCoremField}>
            <Grid item xs={12} md={12} className={classes.gridForm}>
              <TextField
                name="crm_corem"
                defaultValue={""}
                label="CRM DO MÉDICO RESPONSÁVEL PELA ASSINATURA DO LAUDO OU DECLARAÇÃO"
                className={classes.fieldText}
                error={!!userErrors.crm_corem}
                helperText={userErrors.crm_corem}
                required
              />
            </Grid>
          </AllowedField>
          <AllowedField allowed={accordingLawField}>
            <Grid item xs={12} md={12} className={classes.gridForm}>
              <FormControlLabel
                id="according_law"
                control={
                  <WhiteCheckbox
                    checked={user.according_law}
                    name="according_law"
                  />
                }
                label="DECLARO, PARA OS DEVIDOS FINS, QUE AS INFORMAÇÕES ACIMA SÃO VERDADEIRAS E QUE ASSUMO INTEIRA RESPONSABILIDADE PELOS DADOS FORNECIDOS NESTE CADASTRO, RESPONSABILIZANDO-ME CIVIL, CRIMINAL E ADMINISTRATIVAMENTE PELO FORNECIMENTO, OMISSÃO E/OU INCLUSÃO DE DADOS INVERÍDICOS"
              />
              <p style={{ color: "#f44336", margin: 0 }}>
                {userErrors.according_law}
              </p>
            </Grid>
          </AllowedField>
          <AllowedField allowed={healthInstitutionField}>
            <Grid item xs={12} md={12} className={classes.gridForm}>
              <TextField
                name="health_institution"
                defaultValue={""}
                label="NOME DA INSTITUIÇÃO DE SAÚDE QUE TRABALHA"
                className={classes.fieldText}
                error={!!userErrors.health_institution}
                helperText={
                  userErrors.health_institution ||
                  "(Unidade Básica de Saúde, Hospitais, Clínicas, etc)"
                }
                required
              />
            </Grid>
          </AllowedField>
          <Grid item xs={12} md={12} className={classes.gridForm}>
            <AllowedField
              allowed={workSectionField && workSectionFieldOptions.length === 0}
            >
              <TextField
                name="work_section"
                defaultValue={""}
                label="NOME DO SETOR QUE TRABALHA"
                className={classes.fieldText}
                error={!!userErrors.work_section}
                helperText={
                  userErrors.work_section ||
                  "(Emergência, UTI, Enfermaria, Ambulatório, etc)"
                }
                required
              />
            </AllowedField>
            <AllowedField
              allowed={workSectionField && workSectionFieldOptions.length > 0}
            >
              <FormControl className={classes.formControl}>
                <InputLabel
                  className={classes.inputLabel}
                  id="demo-simple-select-label"
                >
                  NOME DO SETOR QUE TRABALHA
                </InputLabel>
                <CommomSelect
                  name="work_section_select"
                  items={workSectionFieldOptions.map((option) => ({
                    value: option,
                    label: option,
                  }))}
                />
              </FormControl>
            </AllowedField>
          </Grid>
          {/* <Grid item xs={12} className={classes.gridDependent}> */}
          {/*   <FormControlLabel */}
          {/*     id="foreigner" */}
          {/*     control={ */}
          {/*       <WhiteCheckbox */}
          {/*         checked={user["foreigner"]} */}
          {/*         onChange={handleCheckbox} */}
          {/*         name="foreigner" */}
          {/*       /> */}
          {/*     } */}
          {/*     label="Estrangeiro?" */}
          {/*   /> */}
          {/* </Grid> */}
          <Grid item xs={12} md={12} className={classes.gridForm}>
            <TextField
              name="name"
              defaultValue={""}
              label="NOME"
              inputRef={nameRef}
              className={classes.fieldText}
              error={!!userErrors.name}
              helperText={userErrors.name}
              required
            />
          </Grid>
          <Grid item xs={12} md={12} className={classes.gridForm}>
            <TextField
              name="social_name"
              defaultValue={""}
              label="NOME SOCIAL (opcional)"
              inputRef={socialNameRef}
              className={classes.fieldText}
              error={!!userErrors.social_name}
              helperText={userErrors.social_name}
            />
          </Grid>
          <Grid item xs={12} md={4} className={classes.gridForm}>
            <div ref={cpfDivRef}>
              <TextField
                name="passport_code"
                defaultValue={""}
                label="PASSAPORTE"
                className={classes.fieldText}
                inputRef={passportCodeRef}
                error={!!userErrors.passport_code}
                helperText={userErrors.passport_code}
                required
              />
            </div>
            <div ref={passportCodeDivRef}>
              <TextField
                name="cpf"
                defaultValue={""}
                label="CPF"
                className={classes.fieldText}
                inputRef={cpfRef}
                error={!!userErrors.cpf}
                helperText={userErrors.cpf}
                required
              />
            </div>
          </Grid>
          <Grid item xs={12} md={4} className={classes.gridForm}>
            <TextField
              name="cartao_sus"
              defaultValue={""}
              label="CARTÃO SUS"
              inputRef={cartaoSusRef}
              className={classes.fieldText}
              error={!!userErrors.cartao_sus}
              helperText={userErrors.cartao_sus}
              required
            />
          </Grid>
          <Grid item xs={12} md={4} className={classes.gridForm}>
            <TextField
              name="phone"
              defaultValue={""}
              label="TELEFONE"
              className={classes.fieldText}
              inputRef={phoneRef}
              error={!!userErrors.phone}
              helperText={userErrors.phone}
              required
            />
          </Grid>
          <Grid item xs={12} md={12} className={classes.gridForm}>
            <TextField
              name="mother_name"
              defaultValue={""}
              label="NOME COMPLETO DA MÃE"
              inputRef={motherNameRef}
              className={classes.fieldText}
              error={!!userErrors.mother_name}
              helperText={userErrors.mother_name}
              required
            />
          </Grid>
          {/* {
						globalSchemaSpa.allow_acamado &&
						<Grid item xs={12} md={12} className={classes.gridForm}>
							<FormControl className={classes.formControl}>
								<InputLabel className={classes.bigInputLabel} id="demo-simple-select-label">TEM ALGUMA NECESSIDADE ESPECIAL QUE IMPOSSIBILITE ACESSO AO CENTRO DE REFERÊNCIA DE VACINAÇÃO</InputLabel>
								<CommomSelect 
								name="special_need"
								defaultValue={'nao_possue'}
								items={
									[
										{ value: 'nao_possue', label: 'Não' }, 
										{ value: 'acamado', label: 'Acamado' }, 
										{ value: 'hospitalizado_mais_30_d_sem_alta', label: 'Hospitalização com mais de 30 dias sem previsão de alta' }
									]
								}
							/>
								</FormControl>
							</Grid>
					}  */}
          <Grid item xs={12} md={6} className={classes.gridForm}>
            <TextField
              name="birthdate"
              defaultValue={""}
              label="DATA DE NASCIMENTO"
              className={classes.fieldText}
              inputRef={birthdateRef}
              error={!!userErrors.birthdate}
              helperText={userErrors.birthdate}
              required
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.gridForm}>
            <FormControl required className={classes.formControl}>
              <InputLabel
                className={classes.inputLabel}
                error={userErrors.raca_cor ? true : false}
                id="demo-simple-select-label"
              >
                RAÇA/COR
              </InputLabel>
              <CommomSelect
                name="raca_cor"
                items={[
                  { value: "Amarela", label: "Amarela" },
                  { value: "Branca", label: "Branca" },
                  { value: "Preta", label: "Preta" },
                  { value: "Parda", label: "Parda" },
                  { value: "Indígena", label: "Indígena" },
                ]}
              />
              <FormHelperText error={userErrors.raca_cor ? true : false}>
                {userErrors.raca_cor}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} className={classes.gridForm}>
            <FormControl required className={classes.formControl}>
              <InputLabel
                className={classes.inputLabel}
                error={userErrors.sexo ? true : false}
                id="demo-simple-select-label"
              >
                SEXO
              </InputLabel>
              <CommomSelect
                name="sexo"
                items={[
                  { value: "Masculino", label: "Masculino" },
                  { value: "Feminino", label: "Feminino" },
                  { value: "Outros", label: "Outros" },
                ]}
              />
              <FormHelperText error={userErrors.sexo ? true : false}>
                {userErrors.sexo}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} className={classes.gridForm}>
            <TextField
              name="cep"
              defaultValue={""}
              label="CEP"
              className={classes.fieldText}
              inputRef={cepRef}
              onBlur={buscaCep}
              error={userErrors.cep ? true : false}
              helperText={userErrors.cep}
              required
            />
          </Grid>
          <Grid item xs={12} md={12} className={classes.gridForm}>
            <TextField
              name="street"
              defaultValue={""}
              InputLabelProps={{ shrink: true }}
              inputRef={streetRef}
              label="RUA"
              className={classes.fieldText}
              error={!!userErrors.street}
              helperText={userErrors.street}
              required
            />
          </Grid>
          <Grid item xs={12} md={4} className={classes.gridForm}>
            <TextField
              name="street_number"
              defaultValue={""}
              label="NÚMERO"
              inputRef={streetNumberRef}
              className={classes.fieldText}
              error={!!userErrors.street_number}
              helperText={userErrors.street_number}
              required
            />
          </Grid>
          <Grid item xs={12} md={4} className={classes.gridForm}>
            <TextField
              name="complement"
              defaultValue={""}
              label="COMPLEMENTO"
              error={!!userErrors.complement}
              helperText={userErrors.complement}
              className={classes.fieldText}
            />
          </Grid>
          <Grid item xs={12} md={4} className={classes.gridForm}>
            <TextField
              name="neighborhood"
              defaultValue={""}
              InputLabelProps={{ shrink: true }}
              inputRef={neighborhoodRef}
              label="BAIRRO"
              className={classes.fieldText}
              error={!!userErrors.neighborhood}
              helperText={userErrors.neighborhood}
              required
            />
          </Grid>
          <Grid item xs={12} md={12} className={classes.gridForm}>
            <TextField
              name="city"
              defaultValue={""}
              InputLabelProps={{ shrink: true }}
              inputRef={cityRef}
              label="CIDADE"
              className={classes.fieldText}
              error={!!userErrors.city}
              helperText={userErrors.city}
              required
            />
          </Grid>
          <Grid item xs={12} md={12} className={classes.gridForm}>
            <TextField
              name="state"
              defaultValue={""}
              InputLabelProps={{ shrink: true }}
              inputRef={stateRef}
              label="UF"
              className={classes.fieldText}
              error={!!userErrors.state}
              helperText={userErrors.state}
              required
            />
          </Grid>
          <Grid item xs={12} className={classes.gridDependent}>
            <FormControlLabel
              id="traditional_community"
              control={
                <WhiteCheckbox
                  checked={user["traditional_community"]}
                  onChange={handleCheckbox}
                  name="traditional_community"
                />
              }
              label="Membro de povo ou comunidade tradicional"
            />
          </Grid>
          <Grid item xs={12} className={classes.gridDependent}>
            <FormControlLabel
              id="dependent"
              control={
                <WhiteCheckbox
                  checked={user["dependent"]}
                  onChange={handleEmail}
                  name="dependent"
                />
              }
              label="DEPENDENTE (CLIQUE SE NÃO TIVER E-MAIL PRÓPRIO)"
            />
          </Grid>
          <Grid item xs={12} className={classes.gridDependent}>
            <h3>Dados médicos: Condições</h3>
            {
              globalSchemaSpa.medical_condition_list.map(conditionItem => {
                return (
                  <div>
                    <FormControlLabel
                      id="traditional_community"
                      control={
                        <WhiteCheckbox
                          name={conditionItem.value}
                          value={conditionItem.value}
                          onClick={handleMedicalConditions}
                        />
                      }
                      label={conditionItem.label}
                    />
                  </div>
                );
            })}
          </Grid>
          {showMedicalConditionOther && (
            <Grid item xs={12} md={12} className={classes.gridForm}>
              <TextField
                name="medical_conditions_other"
                label="Condição médica - Outros"
                className={classes.fieldText}
                error={!!userErrors.medical_conditions_other}
                helperText={userErrors.medical_conditions_other}
                maxRows={2}
                minRows={2}
                multiline
                required
              />
            </Grid>
          )}
          <Grid item xs={12} md={6} className={classes.gridForm}>
            {user.dependent && (
              <TextField
                name="responsible_email"
                label="E-MAIL DO RESPONSÁVEL"
                defaultValue={""}
                inputRef={responsibleEmailRef}
                error={!!userErrors.responsible_email}
                helperText={userErrors.responsible_email}
                className={classes.fieldText}
                required
              />
            )}
            {!user.dependent && (
              <TextField
                name="email"
                label="E-MAIL"
                inputRef={emailRef}
                defaultValue={""}
                error={!!userErrors.email}
                helperText={userErrors.email}
                className={classes.fieldText}
                required
              />
            )}
          </Grid>
          <Grid item xs={12} md={6} className={classes.gridForm}>
            {user.dependent && (
              <TextField
                name="responsible_email_confirmation"
                label="CONFIRMAÇÃO DO E-MAIL DO RESPONSÁVEL"
                defaultValue={""}
                error={!!userErrors.responsible_email_confirmation}
                helperText={userErrors.responsible_email_confirmation}
                className={classes.fieldText}
              />
            )}
            {!user.dependent && (
              <TextField
                name="email_confirmation"
                label="CONFIRMAÇÃO DO E-MAIL"
                defaultValue={""}
                error={!!userErrors.email_confirmation}
                helperText={userErrors.email_confirmation}
                className={classes.fieldText}
              />
            )}
          </Grid>
          <Grid item xs={12} md={6} className={classes.gridForm}>
            <TextField
              name="password"
              defaultValue={""}
              label="SENHA"
              className={classes.fieldText}
              error={!!userErrors.password}
              helperText={userErrors.password}
              type="password"
              required
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.gridForm}>
            <TextField
              name="password_confirmation"
              defaultValue={""}
              label="CONFIRME SUA SENHA"
              className={classes.fieldText}
              error={!!userErrors.password_confirmation}
              helperText={userErrors.password_confirmation}
              type="password"
            />
          </Grid>
          <Grid item xs={12} className={classes.gridForm}>
            <Button
              className={`${classes.whiteBg} ${classes.customBtn}`}
              type="submit"
              disabled={saving}
            >
              {saving ? "AGUARDE..." : "SALVAR"}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}
