import React, { useState } from 'react';
import { Grid, Button, Container } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import GenericCard, { PrimaryCard } from '../components/GenericCard';

const useStyles = makeStyles({
  content: {
    '& h3':{
      color: 'white',
      backgroundColor: props => props.secondary_color,
      display: 'flex',
      width: 'fit-content',
      padding: '7px 8px',
      marginBottom: '20px',
      marginTop: '0px',
      fontWeight: '400 !important',
      letterSpacing: '-1px',
    },
    '& blockquote': {
      borderLeft: props => `5px solid ${props.secondary_color}`,
      paddingLeft: '0.8em',
      marginLeft: '2px'
    },
    '& p, h3, blockquote': {
      fontFamily: "Titillium Web,sans-serif",
      fontSize: '1.6em',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '1.3',
      letterSpacing: '0em',
      marginRight: 'calc(0em * -1)',
      textTransform: 'none',
      transitionTimingFunction: 'cubic-bezier(0.400,0.000,0.200,1.000)',
    },
    '& a': {
      color: 'white',
      textDecoration: 'underline'
    },
    '& a:hover': {
      color: 'white'
    }
  },
  customBtn: {
    borderRadius: '0.35em',
    fontFamily: "Titillium Web, sans-serif",
    transitionTimingFunction: 'cubic-bezier(0.400,0.000,0.200,1.000)',
    transitionDuration: '0.3s',
    boxShadow: 'none',
    border: '0px',
    fontSize: '1.11em',
    fontStyle: 'normal',
    fontWeight: '600 !important',
    lineHeight: '1.3',
    width: '334px',
    height: '51px',
    marginBottom: 15,
    '@media(max-width: 425px)': {
      width: 160,
    },
  },
  actButton: {
    color: 'white !important',
    backgroundColor: props => props.secondary_color,
    borderColor: '#33852d',      
    '&:hover': {
      backgroundColor: '#292929',
      color: 'white !important',
    }
  },
  whiteBg: {
    backgroundColor: 'white',
    color: props => props.primary_color,
    '&:hover': {
      backgroundColor: '#292929',
      color: 'white !important',
    }
	},
	moreContent: {
		fontFamily: "Titillium Web, sans-serif",
		color: 'white',
		fontSize: '1rem',
		fontWeight: 'bold',
		marginBottom: 20,
		display: 'block'
	}



});

export default function Index({ homeContent, homeContentExtra, globalSchemaSpa }) {
  const classes = useStyles(globalSchemaSpa);
  let history = useHistory(); 
	const [moreContent, setMoreContent] = useState(false)

	function toggleMoreContent() {
		setMoreContent(prev => !prev);
	}
  
  return (
    <Container>
      <GenericCard color={"#fff"} borderColor={globalSchemaSpa.primary_color} backgroundColor={globalSchemaSpa.primary_color} title={"INFORMAÇÕES GERAIS"} titleLineColor={globalSchemaSpa.secondary_color}>
        <Grid container>
          <Grid item xs={12}>
            <div dangerouslySetInnerHTML={{ __html: homeContent }} className={classes.content}/>
						<Collapse in={moreContent}>
							<div dangerouslySetInnerHTML={{ __html: homeContentExtra }} className={classes.content}/>
						</Collapse>
						<div style={{ textAlign: 'center' }}>
							{ 
								homeContentExtra &&
								(
									<a href="javascript:;" className={classes.moreContent} onClick={toggleMoreContent}>
										MOSTRAR {moreContent ? 'MENOS' : 'MAIS'}
									</a>
								)
							}        
						</div>
          </Grid>
          <Grid container >
            {/* <Grid item xs={12} md={6} style={{textAlign: 'center'}}>
              <Button className={`${classes.actButton} ${classes.customBtn}`}  onClick={() => history.push({pathname: '/registro'})}>
                CADASTRAR
              </Button>        
            </Grid>
            <Grid item xs={12} md={6} style={{textAlign: 'center'}}>
              <Button className={`${classes.whiteBg} ${classes.customBtn}`} onClick={() => history.push({pathname: '/login'})} >
                ENTRAR/AGENDAR
              </Button>         
            </Grid> */}
          </Grid>
        </Grid>
      </GenericCard>      
    </Container>
  );
}
