import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((color, borderColor, backgroundColor , titleLineColor) => ({
  board: {
    zIndex: 1,
    maxWidth: 800,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: props => props.borderColor,
    padding: '2em 0em 2em 0em',
    fontSize: '1em',
    backgroundColor: props => props.backgroundColor,
    margin: `0 auto`,
    marginBottom: 30,
    '@media(max-width: 425px)': {
      padding: '2em 0em 2em 0em'
    }
  },
  bg: {
    backgroundColor: props => props.backgroundColor,
    color: props => props.color,
  },
  headerDiv: {
    borderWidth: '0px 0px 6px 0px',
    borderStyle: 'solid solid solid solid',
    padding: '0em 0em 0em 2em',
    fontSize: '1em',
    transitionTimingFunction: 'cubic-bezier(0.400,0.000,0.200,1.000)',
    display: 'inline-block',
    margin: 'calc(1rem / 2) 0px',
    borderColor: props => `#0000 #0000 ${props.titleLineColor} #0000`,
    '@media(max-width: 325px)': {
      padding: 0,
    }
  },

  headerText: {
    fontFamily: "Titillium Web,sans-serif",
    fontSize: '2.46em',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '1.3',
    letterSpacing: '-0.05em',
    marginRight: 'calc(-0.05em * -1)',
    textAlign: 'center',
    textTransform: 'uppercase',
    transitionTimingFunction: 'cubic-bezier(0.400,0.000,0.200,1.000)',
    color: props => `${props.color} !important`,
    margin: '0px 0px 5px 0px',
    '@media(max-width: 425px)': {
      fontSize: '1.5em',
    }
  },
  contentDiv: {
    padding: '0px 2em 0px 2em',
    margin: 'calc(1rem / 2) 0px',
  },
}));

export default function GenericCard({ color = "#fff", borderColor = "#005baa", backgroundColor = "#0089c0", title = "INFORMAÇÕES GERAIS", titleLineColor = "#50b848", children }) {
  const classes = useStyles({color, borderColor, backgroundColor , titleLineColor});

  return (
    <div className={classes.board}>
      <div className={classes.bg}>
        <div className={classes.headerDiv} >
          <h2 className={classes.headerText}>{title}</h2>
        </div>
        <div className={classes.contentDiv}>
          {children}
        </div>
      </div>
    </div>
  );
}

export function WhiteCard({children, title, globalSchemaSpa = {} }){

  return (
    <GenericCard color={"#000"} borderColor={"#000"} backgroundColor={"white"} title={title} titleLineColor={globalSchemaSpa.secondary_color}>
      {children}
    </GenericCard>
  );
}

export function PrimaryCard({ children, title, globalSchemaSpa = {} }){
  return (
    <GenericCard color={"#fff"} borderColor={globalSchemaSpa.primary_color} backgroundColor={globalSchemaSpa.primary_color} title={title} titleLineColor={globalSchemaSpa.secondary_color}>
      {children}
    </GenericCard>
  );
}

export function SecondaryCard({ children, title, globalSchemaSpa = {} }){
  return (
    <GenericCard color={"#fff"} borderColor={globalSchemaSpa.secondary_color} backgroundColor={globalSchemaSpa.secondary_color} title={title} titleLineColor={globalSchemaSpa.primary_color}>
      {children}
    </GenericCard>
  );
}